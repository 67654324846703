.App {
  /* text-align: center; */
}
#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.footerLogoImage {
  max-height: 52px;
}

main {
  flex: 1 1 auto;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.Rest-App-header-height {
  height: 88vh;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.error-shake-effect {
  animation: shake 0.2s ease-in-out 0s 2;
  border-radius: 6px;
  box-shadow: 0 0 0.5em red;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }
  /* 25% { margin-left: 1rem; }
  75% { margin-left: -1rem ; } */
  25% {
    margin-left: 0.5rem;
  }
  75% {
    margin-left: -0.5rem;
  }
  100% {
    margin-left: 0rem;
  }
}
/* @keyframes shake {
  from {
    margin-left: 0rem !important;  }
  to {
    margin-left: -5.5rem !important   }
} */

.loading-bottom-layer {
  opacity: 60%;
}

.loading-layer {
  /* z-index: $zindex-fixed; */
  z-index: 1030;
}

/*Hide Arrows From Input Number: Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*Hide Arrows From Input Number: Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* remove all existing decorations for <a> */
.deco-none {
  color: inherit;
  text-decoration: inherit;
}

.deco-none:link {
  color: inherit;
  text-decoration: inherit;
}

.deco-none:hover {
  color: inherit;
  text-decoration: inherit;
}

/* #### Phone number input styles starts here ####*/
.intl-tel-input.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  height: 50px;
}

#phone-number-div {
  position: relative;
}

#send-sms-button {
  position: absolute;
  border-radius: 5px;
  right: 20px;
  z-index: 2;
  border: none;
  top: 6px;
  cursor: pointer;
  color: white;
  background-color: #1e90ff;
  transform: translateX(2px);
}
/* #### Phone number input styles ends here ####*/

/* #### Google translate button styles starts here ####*/
#google_translate_element {
  text-align: center;

  height: 21px;
  margin-top: -10px;
}
.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
body {
  top: 0px !important;
}
#goog-gt-tt {
  display: none !important;
  top: 0px !important;
}
.goog-tooltip skiptranslate {
  display: none !important;
  top: 0px !important;
}
.activity-root {
  display: hide !important;
}
.status-message {
  display: hide !important;
}
.started-activity-container {
  display: hide !important;
}
#google_translate_element select {
  border: none;
  border-radius: 3px;
  padding: 6px 8px;
  background-color: aliceblue;
}
/* #### Google translate button styles ends here ####*/

/* #### App text styles from figma starts here ####*/
.appTitle {
  /* Header/M */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  /* font-size: 32px; */
  line-height: 40px;
  /* identical to box height, or 125% */

  font-feature-settings: "liga" off;

  /* Primary/500 shade */

  color: #2e7fa1;
}

.descriptionFont {
  /* Components/Regular/S */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  font-feature-settings: "liga" off;

  color: #000000;
}

.labelFont {
  /* Components/Regular/XXS */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  font-feature-settings: "liga" off;

  /* Neutral/700 shade */

  color: #4d4d4d;
}
/* #### App text styles from figma ends here ####*/

.consentSectionHeadingFont {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  font-feature-settings: "liga" off;

  color: #000000;
}
.consentCheckboxLabelFont {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  font-feature-settings: "liga" off;
  color: #000000;
}

.userDataFormAlertLabelFont {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  font-feature-settings: "liga" off;

  color: #000000;
}
.userDataFormHeadingFont {
  /* Paragraph/S */

  /* Paragraph/S */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  font-feature-settings: "liga" off;

  /* Base/Black */

  color: #1f1f1f;
}

.userDataFormLabels {
  /* Components/Regular/XXS */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */

  font-feature-settings: "liga" off;

  /* Neutral/500 shade */

  color: #767676;
}

.formResponseHeadingFont {
  /* Header/M */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  /* identical to box height, or 125% */

  font-feature-settings: "liga" off;

  /* Primary/500 shade */

  color: #2e7fa1;
}

.formResponseDescriptionFons {
  /* Components/Regular/M */

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  font-feature-settings: "liga" off;

  color: #000000;
}

.consentCardStyle {
  /* Primary/020 tint */
  background: #c7d9df !important;
  border-radius: 15px !important;
}

.btn-primary {
  /* Primary/500 shade */

  background: #2e7fa1 !important;
  border-radius: 11.6012px !important;
}

.logOutButtonStyle {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 133% */

  display: flex;
  align-items: center;
  text-align: center;
  font-feature-settings: "liga" off;

  /* Primary/500 shade */

  color: #2e7fa1;
}

.sweetAlertConsentTextInfoHeight {
  max-height: 60vh;
  overflow-y: scroll;
}


.consentTextHeadingFont {
  /* Header/M */

  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  /* font-size: 32px;
  line-height: 40px; */
  /* identical to box height, or 125% */

  font-feature-settings: "liga" off;

  /* Primary/500 shade */

  color: #2e7fa1;
}

.breakUrlLink {
  white-space: pre-wrap !important;
  overflow-wrap: break-word !important;
}
.consentTextAlignment {
  text-align: justify !important;
}

@media screen and (max-width:640px) {
  .consentTextAlignment {
      text-align: left !important;
  }
}